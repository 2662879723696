import { useTranslation } from 'react-i18next';
import { sourceAnalytics } from '../../../helpers/report';
import { useMutation } from 'react-query';
import { Card, CardBody, Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import useBanner from '../../../hooks/useBanner';
import SearchForm from '../../../Components/Report/SearchForm';
import { useMemo, useState } from 'react';
import ChartStats from '../../../Components/Report/Common/ChartStats';
import TableSource from '../../../Components/Report/Source/TableSource';

const initialStateSearch = {
	dateRange: {
		start: '',
		end: '',
	},
	callCenters: [],
	hotels: [],
	segments: [],
	departments: [],
	dateOption: 'checkIn',
	sources: [],
};
const activeQuery = {
	agentsOpt: false,
	callCenterOpt: true,
	segmentOpt: true,
	hotelOpt: true,
	mealPlanOpt: false,
	departmentsOpt: true,
	saleStatusOpt: true,
	sourceOpt: true,
	dateOption: true,
};

const Source = () => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'pages.source',
	});
	document.title = t('header');
	const banner = useBanner();
	const [radioOption, setRadioOption] = useState('hotel');

	const {
		mutate: mutateReport,
		isLoading: isSearching,
		data: itemsData,
	} = useMutation(sourceAnalytics, {
		onError: (error) => {
			banner.simpleError(error);
		},
	});

	const { departments, hotels, callCenters } = useMemo(() => {
		if (itemsData !== undefined) {
			return {
				hotels: itemsData.hotels.map((item) => ({
					name: item.hotel,
					total: item.total,
					percentage: item.percentage,
				})),
				callCenters: itemsData.callCenters.map((item) => ({
					name: item.callCenter,
					total: item.total,
					percentage: item.percentage,
				})),
				departments: itemsData.departments.map((item) => ({
					name: item.department,
					total: item.total,
					percentage: item.percentage,
				})),
			};
		}
		return {
			departments: [],
			hotels: [],
			callCenters: [],
		};
	}, [itemsData]);

	const onHandleSubmit = (body) => {
		setRadioOption('hotel');
		mutateReport(body);
	};

	return (
		<div className="page-content">
			<Container fluid>
				<BreadCrumb
					title={t('title')}
					pageTitle={t('arrivals')}
					urlPageTitle="/revenue"
				/>

				<Row>
					<Col>
						<Card className="shadow">
							<CardBody className="pt-0">
								<div className="py-3">
									<SearchForm
										handleSubmit={onHandleSubmit}
										initialState={initialStateSearch}
										activeQuery={activeQuery}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>

				<Row>
					<Col xs={12} lg={4}>
						<ChartStats
							items={hotels}
							isSearching={isSearching}
							title={t('chartHotelTitle')}
							label={t('total')}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<ChartStats
							items={departments}
							isSearching={isSearching}
							title={t('chartDepartmentTitle')}
							label={t('total')}
						/>
					</Col>
					<Col xs={12} lg={4}>
						<ChartStats
							items={callCenters}
							isSearching={isSearching}
							title={t('chartCallCenterTitle')}
							label={t('total')}
						/>
					</Col>
				</Row>

				{!isSearching && itemsData && (
					<Row>
						<Col xs="12" md="12">
							<div className="d-flex justify-content-end align-items-center mb-2">
								<div className="d-flex flex-1">
									<div className="form-check me-4">
										<Input
											className="form-check-input"
											type="radio"
											id="showByHotel"
											name="groupBy"
											checked={radioOption === 'hotel'}
											onChange={(evt) =>
												setRadioOption('hotel')
											}
										/>
										<Label
											className="form-check-label"
											htmlFor="showByHotel"
										>
											{t('showByHotel')}
										</Label>
									</div>
									<div className="form-check me-4">
										<Input
											className="form-check-input"
											type="radio"
											id="showByDepartments"
											name="groupBy"
											checked={
												radioOption === 'departments'
											}
											onChange={(evt) =>
												setRadioOption('departments')
											}
										/>
										<Label
											className="form-check-label"
											htmlFor="showByDepartments"
										>
											{t('showByDepartments')}
										</Label>
									</div>
									<div className="form-check">
										<Input
											className="form-check-input"
											type="radio"
											id="showByCallcenter"
											name="groupBy"
											checked={
												radioOption === 'callcenter'
											}
											onChange={(evt) =>
												setRadioOption('callcenter')
											}
										/>
										<Label
											className="form-check-label"
											htmlFor="showByCallcenter"
										>
											{t('showByCallcenter')}
										</Label>
									</div>
								</div>
							</div>
						</Col>
						<Col xs="12" md="12">
							<TableSource
								items={itemsData}
								showBy={radioOption}
							/>
						</Col>
					</Row>
				)}
			</Container>
		</div>
	);
};

export default Source;
