import { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { arrivalsAnalytics } from '../../../helpers/report';
import { useMutation } from 'react-query';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import ArrivalsSearch from '../../../Components/Report/Arrivals/ArrivalsSearch';
import ResumeWidgets from '../../../Components/Charts/ResumeWidgets';
import ChartArrivalsByHotel from '../../../Components/Report/Arrivals/ChartArrivalsByHotel';
import ChartArrivalsByCallCenter from '../../../Components/Report/Arrivals/ChartArrivalsByCallCenter';
import ChartArrivalsByAgent from '../../../Components/Report/Arrivals/ChartArrivalsByAgent';
import ReservationCanvas from '../../../Components/Common/ReservationCanvas';
import useBanner from '../../../hooks/useBanner';

export const getTopAgent = (users) => {
	const topAgent = users.reduce((maxObj, obj) => {
		return obj.total > maxObj.total ? obj : maxObj;
	}, users[0]);
	return topAgent;
};
export const getTopHotel = (hotels) => {
	const top = hotels.reduce((maxObj, obj) => {
		return obj.total > maxObj.total ? obj : maxObj;
	}, hotels[0]);
	return top;
};
export const getTopCC = (callCenters) => {
	const top = callCenters.reduce((maxObj, obj) => {
		return obj.total > maxObj.total ? obj : maxObj;
	}, callCenters[0]);
	return top;
};

export const getUsersOrder = (users) => {
	const sortedData = users.sort((a, b) => b.total - a.total);
	return sortedData;
};

const Arrivals = () => {
	const { t } = useTranslation('translation', {
		keyPrefix: 'pages.arrivals',
	});
	document.title = t('header');
	const banner = useBanner();
	const [filter, setFilter] = useState(null);
	const [showCanva, setShowCanva] = useState(false);
	const {
		mutate: mutateReport,
		isLoading: isSearching,
		data: itemsData,
	} = useMutation(arrivalsAnalytics, {
		onError: (error) => {
			banner.simpleError(error);
		},
	});

	const getMainInfo = useCallback((data) => {
		const arr = [];
		arr.push({
			id: 1,
			label: 'Reservaciones',
			badge: '',
			icon: 'ri-calendar-check-line',
			counter: data.total,
			decimals: 0,
			suffix: '',
			prefix: '',
			onClick: undefined,
		});
		if (data.users.length > 0) {
			arr.push({
				id: 2,
				label: `Top Agent`,
				subLabel: getTopAgent(data.users).user,
				subLabelIcon: 'ri-user-fill',
				badge: '',
				icon: 'ri-calendar-check-line',
				counter: getTopAgent(data.users).total,
				decimals: 0,
				suffix: '',
				prefix: '',
				onClick: () =>
					onClickShowCanva(getTopAgent(data.users).user, 'Agent'),
			});
		}
		if (data.hotels.length > 0) {
			arr.push({
				id: 3,
				label: `Top Hotel`,
				subLabel: getTopHotel(data.hotels).hotel,
				subLabelIcon: 'ri-hotel-fill',
				badge: '',
				icon: 'ri-calendar-check-line',
				counter: getTopHotel(data.hotels).total,
				decimals: 0,
				suffix: '',
				prefix: '',
				onClick: () =>
					onClickShowCanva(getTopHotel(data.hotels).idHotel, 'Hotel'),
			});
		}
		if (data.callCenters.length > 0) {
			arr.push({
				id: 4,
				label: `Top Call Center`,
				subLabel: getTopCC(data.callCenters).callCenter,
				subLabelIcon: 'bx bxs-phone-call',
				badge: '',
				icon: 'ri-calendar-check-line',
				counter: getTopCC(data.callCenters).total,
				decimals: 0,
				suffix: '',
				prefix: '',
				onClick: () =>
					onClickShowCanva(
						getTopCC(data.callCenters).idCallcenter,
						'CALLCENTER'
					),
			});
		}

		return arr;
	}, []);

	const { agents, hotels, callCenters, crmWidgets } = useMemo(() => {
		if (itemsData !== undefined) {
			return {
				agents: getUsersOrder(itemsData.users),
				hotels: itemsData.hotels,
				callCenters: itemsData.callCenters,
				crmWidgets: getMainInfo(itemsData),
			};
		}
		return {
			agents: [],
			hotels: [],
			callCenters: [],
			crmWidgets: [],
		};
	}, [itemsData, getMainInfo]);

	const onClickShowCanva = (key, type) => {
		const salesAgent = filter?.salesAgent ?? '';
		const hotel = filter?.hotel ?? '';
		const callCenter = filter?.callCenter ?? '';
		switch (type) {
			case 'Agent':
				setFilter((prev) => ({
					...prev,
					callCenter: Array.isArray(callCenter) ? callCenter : '',
					hotel: Array.isArray(hotel) ? hotel : '',
					salesAgent: key,
				}));
				break;
			case 'Hotel':
				setFilter((prev) => ({
					...prev,
					callCenter: Array.isArray(callCenter) ? callCenter : '',
					salesAgent: Array.isArray(salesAgent) ? salesAgent : '',
					hotel: key,
				}));
				break;
			case 'CALLCENTER':
				setFilter((prev) => ({
					...prev,
					hotel: Array.isArray(hotel) ? hotel : '',
					salesAgent: Array.isArray(salesAgent) ? salesAgent : '',
					callCenter: key,
				}));
				break;
			default:
				return;
		}
		setShowCanva(true);
	};

	const onHandleSubmit = (body) => {
		setFilter({
			checkInStart: body.dateRange.start,
			checkInEnd: body.dateRange.end,
			segment: body.segments,
			fromReport: 'true',
			callCenter: body.callCenters,
			mealPlan: body.mealPlans,
			hotel: body.hotels,
			salesAgent: body.salesAgents,
		});
		mutateReport(body);
	};

	return (
		<div className="page-content">
			<Container fluid>
				<BreadCrumb
					title={t('title')}
					pageTitle={t('arrivals')}
					urlPageTitle="/revenue"
				/>

				<Row>
					<Col>
						<Card className="shadow">
							<CardBody className="pt-0">
								<div className="py-3">
									<ArrivalsSearch
										handleSubmit={onHandleSubmit}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
				{crmWidgets.length > 0 && (
					<Row>
						<ResumeWidgets crmWidgets={crmWidgets} />
					</Row>
				)}

				<Row>
					<Col xs={12} lg={3}>
						<ChartArrivalsByHotel
							items={hotels}
							isSearching={isSearching}
						/>
					</Col>
					<Col xs={12} lg={3}>
						<ChartArrivalsByCallCenter
							items={callCenters}
							isSearching={isSearching}
						/>
					</Col>
					<Col xs={12} lg={6}>
						<ChartArrivalsByAgent
							items={agents}
							isSearching={isSearching}
						/>
					</Col>
				</Row>
			</Container>
			<ReservationCanvas
				show={showCanva}
				onCloseClick={() => setShowCanva(false)}
				filter={filter}
			/>
		</div>
	);
};

export default Arrivals;
