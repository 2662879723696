import { useTranslation } from 'react-i18next';
import useUser from '../../hooks/useUser';
import { useState } from 'react';
import { getAgentsBySupervisor } from '../../helpers/customer';
import { useQuery } from 'react-query';
import { getCallCenterByUser } from '../../helpers/catalogues/call_center';
import { getHotelAll } from '../../helpers/catalogues/hotel';
import { getMealPlanAll } from '../../helpers/catalogues/meal_plan';
import { getDepartamentList } from '../../helpers/configuration/departament';
import { Col, Form, FormFeedback, Label, Row } from 'reactstrap';
import moment from 'moment';
import DatePicker from '../Common/DatePicker';
import { FIELD_REQUIRED, SELECT_OPTION } from '../constants/messages';
import SelectAsync from '../Common/SelectAsync';
import { getSegmentPaginate } from '../../helpers/catalogues/segment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import useGetReservationStatusAllQuery from '../../hooks/Queries/useGetReservationStatusAllQuery';
import useGetSourcePaginateQuery from '../../hooks/Queries/useGetSourcePaginateQuery';

const SearchForm = ({ handleSubmit, initialState, activeQuery }) => {
	const user = useUser();
	const { t: tMessage } = useTranslation('translation', {
		keyPrefix: 'messages',
	});
	const { t } = useTranslation('translation', {
		keyPrefix: 'components.searchForm',
	});

	const dateOpt = [
		{ value: 'checkIn', label: 'Fecha llegada' },
		{ value: 'dateRequest', label: 'Fecha de creación' },
	];

	const [date, setDate] = useState([]);
	const [objSearch, setObjSearch] = useState({
		hotels: null,
		callCenters: null,
		segments: null,
		mealPlans: null,
		saleStatus: null,
		departments: null,
		source: null,
		dateOption: initialState.dateOption
			? {
					value: initialState?.dateOption,
					label:
						dateOpt.find(
							(it) => it.value === initialState?.dateOption
						)?.label ?? initialState?.dateOption,
			  }
			: null,
	});

	//source
	const { data: sourceOpt } = useGetSourcePaginateQuery(
		'?enabled=1&max=100',
		activeQuery.sourceOpt,
		false
	);

	//status reservation
	const { data: saleStatusOpt } = useGetReservationStatusAllQuery(
		activeQuery.saleStatusOpt
	);

	//agents by super/manager
	const { data: agentsOpt } = useQuery(
		['getAgentsBySupervisor', user.usuario],
		() => getAgentsBySupervisor(user.usuario),
		{
			enabled: user !== null && activeQuery.agentsOpt,
			select: (result) =>
				result.data.list.map((it) => ({
					value: it.id,
					label: it.id,
				})) ?? [],
		}
	);
	//getCallCenter
	const { data: callCenterOpt } = useQuery(
		['getCallCenterByUser'],
		() => getCallCenterByUser(user?.usuario),
		{
			enabled: user?.usuario !== undefined && activeQuery.callCenterOpt,
			select: (data) =>
				data.data?.list.map((item) => ({
					value: item.id,
					label: item.name,
				})) ?? [],
		}
	);
	//getHotel
	const { data: hotelOpt } = useQuery(['getHotelAll'], () => getHotelAll(), {
		enabled: activeQuery.hotelOpt,
		select: (data) =>
			data.data?.list.map((item) => ({
				value: item.id,
				label: item.name,
			})) ?? [],
	});
	//getMealPlan
	const { data: mealPlanOpt } = useQuery(
		['getMealPlanAll'],
		() => getMealPlanAll(),
		{
			enabled: activeQuery.mealPlanOpt,
			select: (data) =>
				data.data?.list.map((item) => ({
					value: item.id,
					label: item.plan,
				})) ?? [],
		}
	);
	//get depto
	const { data: departmentsOpt } = useQuery(
		['getDepartamentList'],
		async () => {
			const response = await getDepartamentList();
			return response;
		},
		{
			enabled: activeQuery.departmentsOpt,
			select: (response) =>
				response.data.list.map((it) => ({
					value: it.id,
					label: it.name,
				})),
		}
	);

	const formik = useFormik({
		initialValues: initialState,
		validationSchema: Yup.object({
			dateRange: Yup.object().shape({
				end: Yup.string().required(tMessage(FIELD_REQUIRED)),
			}),
		}),
		onSubmit: async (values) => {
			let body = {
				...values,
				userName: user?.usuario,
			};
			if (activeQuery.agentsOpt) {
				if (
					objSearch.departments?.length > 0 &&
					(objSearch.agents?.length === 0 ||
						objSearch.agents === null)
				) {
					const agentsToSend = agentsOpt.filter((it) =>
						objSearch.departments
							.map((itD) => itD.id)
							.includes(it.iddepartment)
					);
					body = {
						...body,
						salesAgents: agentsToSend.map((it) => it.value),
					};
				}
			}

			handleSubmit(body);
		},
	});

	return (
		<Form
			className="needs-validation fs-7"
			onSubmit={(e) => {
				e.preventDefault();
				formik.handleSubmit();
				return false;
			}}
		>
			<Row>
				{activeQuery.agentsOpt && (
					<Col xs={12} md="3">
						<div className="mb-2">
							<Label
								className="form-label mb-0 d-flex align-items-center"
								htmlFor="agent"
							>
								{t('agent')}
							</Label>
							<Select
								value={objSearch.agents}
								isMulti
								onChange={(value) => {
									setObjSearch((prev) => ({
										...prev,
										agents: value,
									}));
									formik.setFieldValue(
										'salesAgents',
										value.map((it) => it.value)
									);
								}}
								options={agentsOpt}
								classNamePrefix="select2-selection"
								placeholder={tMessage(SELECT_OPTION)}
							/>
						</div>
					</Col>
				)}
				{activeQuery.callCenterOpt && (
					<Col xs={12} md="3">
						<div className="mb-2">
							<Label
								htmlFor="call-center"
								className="form-label mb-0"
							>
								Call center
							</Label>
							<Select
								id="hotel"
								className="mb-0"
								value={objSearch.callCenter}
								onChange={(value) => {
									setObjSearch((prev) => ({
										...prev,
										callCenter: value,
									}));
									formik.setFieldValue(
										'callCenters',
										value.map((it) => it.value)
									);
								}}
								options={callCenterOpt}
								placeholder={tMessage(SELECT_OPTION)}
								isClearable
								isMulti
							/>
						</div>
					</Col>
				)}
				{activeQuery.segmentOpt && (
					<Col xs="12" md="3">
						<div className="mb-2">
							<Label
								htmlFor="segmento"
								className="form-label mb-0"
							>
								{t('segment')}
							</Label>
							<SelectAsync
								fnFilter={getSegmentPaginate}
								query={'?page=1&max=10'}
								keyCompare={'name'}
								value={objSearch.segment}
								onChange={(value) => {
									setObjSearch((prev) => ({
										...prev,
										segment: value,
									}));
									formik.setFieldValue(
										'segments',
										value.map((it) => it.value)
									);
								}}
								isClearable
								isMulti
							/>
						</div>
					</Col>
				)}
				{activeQuery.hotelOpt && (
					<Col xs="12" md="3">
						<div className="mb-2">
							<Label className="form-label mb-0" htmlFor="hotel">
								Hotel
							</Label>
							<Select
								id="hotel"
								className="mb-0"
								value={objSearch.hotel}
								onChange={(value) => {
									setObjSearch((prev) => ({
										...prev,
										hotel: value,
									}));
									formik.setFieldValue(
										'hotels',
										value.map((it) => it.value)
									);
								}}
								options={hotelOpt}
								placeholder={tMessage(SELECT_OPTION)}
								isClearable
								isMulti
							/>
						</div>
					</Col>
				)}
				{activeQuery.mealPlanOpt && (
					<Col xs="12" md="3">
						<div className="mb-2">
							<Label className="form-label mb-0" htmlFor="plan">
								{t('plan')}
							</Label>
							<Select
								id="hotel"
								className="mb-0"
								value={objSearch.mealPlans}
								onChange={(value) => {
									setObjSearch((prev) => ({
										...prev,
										mealPlans: value,
									}));
									formik.setFieldValue(
										'mealPlans',
										value.map((it) => it.value)
									);
								}}
								options={mealPlanOpt}
								isClearable
								isMulti
								placeholder={tMessage(SELECT_OPTION)}
							/>
						</div>
					</Col>
				)}
				{activeQuery.departmentsOpt && (
					<Col xs={12} md="3">
						<div className="mb-2">
							<Label
								className="form-label mb-0"
								htmlFor="departments"
							>
								{t('departments')}
							</Label>
							<Select
								id="category"
								className="mb-0"
								value={objSearch.departments}
								onChange={(value) => {
									setObjSearch((prev) => ({
										...prev,
										departments: value,
									}));
									formik.setFieldValue(
										'departments',
										value.map((it) => it.value)
									);
								}}
								options={departmentsOpt}
								isMulti={true}
								placeholder={tMessage(SELECT_OPTION)}
							/>
						</div>
					</Col>
				)}
				{activeQuery.saleStatusOpt && (
					<Col xs="12" md={3}>
						<div className="mb-2">
							<Label
								className="form-label mb-0"
								htmlFor="saleStatus"
							>
								{t('saleStatus')}
							</Label>
							<Select
								value={objSearch.salesStatus}
								onChange={(value) => {
									setObjSearch((prev) => ({
										...prev,
										salesStatus: value,
									}));
									formik.setFieldValue(
										'salesStatus',
										value.map((it) => it.value)
									);
								}}
								isMulti
								options={saleStatusOpt}
								classNamePrefix="select2-selection"
								placeholder={tMessage(SELECT_OPTION)}
								isClearable
							/>
						</div>
					</Col>
				)}
				{activeQuery.sourceOpt && (
					<Col xs="12" md={3}>
						<div className="mb-2">
							<Label className="form-label mb-0" htmlFor="source">
								Source
							</Label>
							<Select
								value={objSearch.source}
								onChange={(value) => {
									setObjSearch((prev) => ({
										...prev,
										source: value,
									}));
									formik.setFieldValue(
										'sources',
										value.map((it) => it.value)
									);
								}}
								isMulti
								options={sourceOpt}
								classNamePrefix="select2-selection"
								placeholder={tMessage(SELECT_OPTION)}
								isClearable
							/>
						</div>
					</Col>
				)}
				{activeQuery.dateOption && (
					<Col xs="12" md={3}>
						<div className="mb-2">
							<Label className="form-label mb-0" htmlFor="source">
								Tipo fecha
							</Label>
							<Select
								value={objSearch.dateOption}
								onChange={(value) => {
									setObjSearch((prev) => ({
										...prev,
										dateOption: value,
									}));
									formik.setFieldValue(
										'dateOption',
										value?.value ?? ''
									);
								}}
								options={dateOpt}
								classNamePrefix="select2-selection"
								placeholder={tMessage(SELECT_OPTION)}
							/>
						</div>
					</Col>
				)}
				<Col xs="12" md={3}>
					<div className="mb-2">
						<Label
							className="form-label mb-0 d-flex align-items-center"
							htmlFor="rangeDate"
						>
							{t('dateRange')}
						</Label>
						<div className="input-group">
							<DatePicker
								id="rangeDate"
								className="form-control"
								options={{
									mode: 'range',
								}}
								date={date}
								onChangeDate={(e) => {
									setDate(e);
									if (e.length === 2) {
										formik.setFieldValue(
											'dateRange.start',
											moment(e[0]).format('YYYY-MM-DD')
										);
										formik.setFieldValue(
											'dateRange.end',
											moment(e[1]).format('YYYY-MM-DD')
										);
									}
								}}
								placeholder={'DD/MM/YYYY a DD/MM/YYYY'}
								onClose={(selectedDates) => {
									if (selectedDates.length === 0) setDate([]);
								}}
							/>
							<div className="input-group-text bg-light text-dark">
								<i className="ri-calendar-2-line"></i>
							</div>
						</div>
						{formik.errors.dateRange?.end && (
							<FormFeedback type="invalid" className="d-block">
								{formik.errors.dateRange?.end}
							</FormFeedback>
						)}
					</div>
				</Col>
				<Col xs="12" md={3}>
					<div className="mb-2">
						<label className="form-label mb-0 d-flex align-items-center opacity-0">
							button
						</label>
						<div>
							<button
								className="btn btn-info ms-1"
								onClick={() => {}}
							>
								<i className="mdi mdi-magnify"></i>{' '}
								{t('search')}
							</button>
						</div>
					</div>
				</Col>
				<Col xs="12" md={2}></Col>
			</Row>
		</Form>
	);
};

export default SearchForm;
