import { useQuery } from 'react-query';
import { getSourcePaginate } from '../../helpers/catalogues/source';

const useGetSourcePaginateQuery = (queryPath, enabled = true, full = true) => {
	const query = useQuery(
		['getSourcePaginate', queryPath],
		() => getSourcePaginate(queryPath),
		{
			enabled: Boolean(queryPath) && enabled,
			select: (result) => {
				if (full) {
					return result;
				} else {
					return (
						result.data.list.map((it) => ({
							value: it.id,
							label: it.campaign,
						})) ?? []
					);
				}
			},
		}
	);

	return query;
};

export default useGetSourcePaginateQuery;
